import './NavBar.scss';

import { Groups3, QueryStats } from '@mui/icons-material';
import MenuIcon from '@mui/icons-material/Menu';
import {
  AppBar,
  Box,
  BoxProps,
  Button,
  Container,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material';
import { observer } from 'mobx-react';
import React from 'react';
import { Link } from 'react-router-dom';
import { NetworkSelector } from 'src/components/NetworkSelector/NetworkSelector';

import { useStores } from '../../../../store/RootStore';

const NavBar = observer(() => {
  const {
    AppStore: { user },
  } = useStores();
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );

  const pages = [
    {
      name: 'Analytics',
      link: `/account/${user?.quicknodeId}`,
      icon: <QueryStats fontSize="small" />,
    },
    {
      name: 'Config',
      link: `/account/${user?.quicknodeId}/config`,
      icon: <Groups3 fontSize="small" />,
    },
  ];

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = (): void => {
    setAnchorElNav(null);
  };

  const LogoBox = (props: BoxProps): JSX.Element => {
    return (
      <Box className="logo-box" {...props}>
        <Box
          component="img"
          sx={{
            maxHeight: '34px',
          }}
          alt="Meowrpc"
          src="/meowlogo.svg"
        />
        <Box
          sx={{
            fontSize: '28px',
            lineHeight: '35px',
            margin: '0 10px',
            fontWeight: 'bold',
          }}>
          {'&'}
        </Box>
        <Box
          component="img"
          sx={{
            maxHeight: '38px',
          }}
          alt="Quicknode"
          src="/qn-logo.svg"
        />
      </Box>
    );
  };

  return (
    <AppBar className="nav-bar">
      <Container sx={{ maxWidth: '1300px', p: '0' }} maxWidth="xl">
        <Toolbar sx={{ px: { xs: 2 } }}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={0}
            sx={{ width: '100%' }}>
            <NetworkSelector />
            <LogoBox
              className={'logo-box'}
              sx={{
                display: {
                  xs: 'none',
                  md: 'flex',
                  position: 'relative',
                },
              }}
            />
            {!!user && (
              <>
                <Box sx={{ flexGrow: 0, display: { xs: 'none', md: 'flex' } }}>
                  {pages.map((page, i) => (
                    <Button
                      key={'nav-button-' + i}
                      size="large"
                      sx={{ display: 'block', fontWeight: 'bold' }}
                      component={Link}
                      to={page.link}
                      color="inherit">
                      {page.name}
                    </Button>
                  ))}
                </Box>
                <Box sx={{ flexGrow: 0, display: { xs: 'flex', md: 'none' } }}>
                  <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleOpenNavMenu}>
                    <MenuIcon />
                  </IconButton>
                  <Menu
                    id="menu-appbar"
                    anchorEl={anchorElNav}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                    open={Boolean(anchorElNav)}
                    onClose={handleCloseNavMenu}
                    sx={{
                      display: { xs: 'block', md: 'none' },
                    }}>
                    {pages.map((page, i) => (
                      <MenuItem
                        key={'menu-item-' + i}
                        component={Link}
                        to={page.link}>
                        <ListItemIcon>{page.icon}</ListItemIcon>
                        <ListItemText>{page.name}</ListItemText>
                      </MenuItem>
                    ))}
                  </Menu>
                </Box>
              </>
            )}
            {!user && (
              <Tooltip
                title={
                  <Typography>
                    Log in from your quicknode account for advanced access
                  </Typography>
                }
                arrow>
                <Button
                  size="large"
                  sx={{ display: 'block', fontWeight: 'bold' }}
                  color="inherit">
                  Analytics only
                </Button>
              </Tooltip>
            )}
          </Stack>
        </Toolbar>
      </Container>
    </AppBar>
  );
});

export default NavBar;
