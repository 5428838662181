import { Box, Container, CssBaseline } from '@mui/material';
import { ReactNode } from 'react';

import Footer from './components/Footer/Footer';
import NavBar from './components/NavBar/NavBar';

const Layout = (props: { children: ReactNode }): JSX.Element => {
  return (
    <Box
      sx={{
        background:
          'linear-gradient(90deg, rgba(127,0,255,1) 0%, rgba(225,0,255,1) 100%)',
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
      }}>
      <CssBaseline />
      <NavBar />
      <Container
        component="main"
        sx={{ mt: 12, mb: 0, maxWidth: '1300px' }}
        maxWidth="xl">
        {props.children}
      </Container>
      <Footer
        sx={{
          py: 2,
          px: 2,
          mt: 'auto',
        }}
      />
    </Box>
  );
};

export default Layout;
