import { Checkbox, FormControlLabel, Grid } from '@mui/material';
import { Control, Controller, FieldValues } from 'react-hook-form';
import { BlockbuildersEnum } from 'src/shared/enums/blockbuilders.enum';

type TrustedBroadcastersControllerProps = {
  name: string;
  control: Control<FieldValues>;
  disabled?: boolean;
};

export const TrustedBroadcastersController = (
  props: TrustedBroadcastersControllerProps
): JSX.Element => {
  const { control, name, disabled } = props;

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { ...field } }) => (
        <>
          {Object.values(BlockbuildersEnum).map((blockbuilder) => {
            return (
              <Grid item key={blockbuilder} xs={6} md={4}>
                <FormControlLabel
                  labelPlacement="end"
                  control={
                    <Checkbox
                      value={blockbuilder}
                      disabled={disabled}
                      checked={
                        !!field.value && field.value.includes(blockbuilder)
                      }
                      onChange={(event, checked) => {
                        if (checked) {
                          if (field.value) {
                            field.onChange([
                              ...field.value,
                              event.target.value,
                            ]);
                          }
                        } else {
                          field.onChange(
                            field?.value?.filter(
                              (value: string) => value !== event.target.value
                            )
                          );
                        }
                      }}
                    />
                  }
                  label={
                    blockbuilder === BlockbuildersEnum.backrunme
                      ? 'bloXroute2'
                      : blockbuilder
                  }
                  {...field}
                />
              </Grid>
            );
          })}
        </>
      )}
    />
  );
};
