import axios, { AxiosInstance } from 'axios';

const BASE_URL = process.env.REACT_APP_API_URL;

export const getApiClient = (): AxiosInstance => {
  const authApi = axios.create({
    baseURL: BASE_URL,
    withCredentials: true,
  });

  authApi.defaults.headers.common['Content-Type'] = 'application/json';

  authApi.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      const originalRequest = error.config;
      const errMessage = error.response.data?.message || error.message;
      if (errMessage.includes('Unauthorized') && !originalRequest._retry) {
        originalRequest._retry = true;
        return authApi(originalRequest);
      }
      return Promise.reject(error);
    }
  );

  return authApi;
};
