import { Backdrop, Box, CircularProgress } from '@mui/material';
import {
  ArcElement,
  BubbleDataPoint,
  Chart as ChartJS,
  ChartData,
  ChartDataset,
  Legend,
  Point,
  Tooltip,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Doughnut } from 'react-chartjs-2';
import { networks } from 'src/config';
import { useAnalytics } from 'src/hooks';
import { ChainIdEnum } from 'src/shared/enums/chain-id.enum';

ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

export const BroadcasterRequestsDoughnut = (): JSX.Element => {
  const stats = useAnalytics();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const dataset: ChartDataset<any> = {
    label: 'Requests',
    data: [],
    backgroundColor: [],
    borderWidth: 1,
  };

  const data: ChartData<
    'doughnut',
    (number | [number, number] | Point | BubbleDataPoint | null)[],
    unknown
  > = {
    labels: [],
    datasets: [dataset],
  };

  const chains = Object.keys(stats.data?.requests?.all?.chains || {});
  chains.forEach((chainId) => {
    let value =
      stats.data.requests.all.chains[chainId as unknown as ChainIdEnum] || 0;

    const duplicates =
      stats.data.requests?.duplicateTransaction?.chains[
        chainId as unknown as ChainIdEnum
      ] || 0;

    if (value && duplicates) {
      if (duplicates > value) {
        value = 0;
      } else {
        value -= duplicates;
      }
    }

    const currentNetwork = networks.find(
      (network) => network.chainId === +chainId
    );

    if (data.labels) {
      data.labels.push(currentNetwork?.name || 'Chain ID: ' + chainId);
    }

    dataset.data.push(value);

    if (
      dataset.backgroundColor &&
      typeof dataset.backgroundColor !== 'string'
    ) {
      dataset.backgroundColor.push(
        (currentNetwork?.color || '#9f8080') as string
      );
    }
  });

  let total = stats.data?.requests?.all?.total || 0;
  const duplicates = stats.data?.requests?.duplicateTransaction?.total || 0;
  if (total > 0 && duplicates) {
    if (duplicates > total) {
      total = 0;
    } else {
      total -= duplicates;
    }
  }

  return (
    <Box
      sx={{
        mx: 'auto',
        maxWidth: '250px',
        backgroundColor: '#f8f8f8',
        borderRadius: '250px',
        position: 'relative',
      }}>
      <Backdrop
        open={stats.isLoading}
        sx={{
          position: 'absolute',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          color: '#ddd',
          background: 'none',
        }}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Doughnut
        data={data}
        options={{
          plugins: {
            datalabels: {
              display: false,
            },
            legend: {
              display: false,
            },
          },
        }}
      />
      <Box
        display={'block'}
        textAlign={'center'}
        sx={{
          position: 'absolute',
          fontWeight: 'bold',
          top: '50%',
          width: '100%',
          lineHeight: '40px',
          mt: '-20px',
          fontSize: '22px',
        }}>
        {total}
      </Box>
    </Box>
  );
};
