import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import { Box } from '@mui/system';
import { ReactNode, useState } from 'react';

const AreYouSure = (props: {
  onClick: () => void;
  children: ReactNode;
  text?: string;
}): JSX.Element => {
  const { text, onClick } = props;
  const [open, setOpen] = useState(false);

  const handleClickOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const handleYes = (): void => {
    if (onClick) {
      onClick();
    }
    handleClose();
  };

  return (
    <>
      <Box component={'span'} onClick={handleClickOpen}>
        {props.children}
      </Box>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle
          id="alert-dialog-title"
          sx={{ textAlign: 'center', minWidth: '250px' }}>
          {text || 'Are you sure?'}
        </DialogTitle>
        <DialogActions sx={{ justifyContent: 'space-between' }}>
          <Button onClick={handleClose} color={'primary'}>
            No
          </Button>
          <Button onClick={handleYes} color={'primary'} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AreYouSure;
