import { Box, Grid, Paper, Stack, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { BroadcasterRequestsDoughnut } from 'src/components/BroadcasterRequestsDoughnut/BroadcasterRequestsDoughnut';
import { BroadcasterStatsTable } from 'src/components/BroadcasterStatsTable/BroadcasterStatsTable';
import { BundleList } from 'src/components/BundleList/BundleList';
import { PeriodSelector } from 'src/components/PeriodSelector/PeriodSelector';
import Title from 'src/components/Title/Title';
import { ChainIdEnum } from 'src/shared/enums/chain-id.enum';

const Analytics = observer((): JSX.Element => {
  const [searchParams, setSearchParams] = useSearchParams();
  const chainId = searchParams.get('network');

  useEffect(() => {
    if (!chainId) {
      searchParams.set('network', String(ChainIdEnum.eth));
      setSearchParams(searchParams);
    }
  }, [chainId, searchParams, setSearchParams]);

  return (
    <Box
      component={Paper}
      sx={{
        px: 2,
        py: 4,
      }}>
      <Title>Analytics</Title>
      {!!chainId && (
        <>
          <Box sx={{ mb: 4 }}>
            <Stack
              direction={{ md: 'row', xs: 'column' }}
              justifyContent="space-between"
              alignItems="center">
              <Typography variant="h2">Transactions for the period</Typography>
              <Stack
                direction={{ md: 'row', xs: 'column' }}
                justifyContent="space-between">
                <PeriodSelector sx={{ width: '110px' }} />
              </Stack>
            </Stack>
            <Grid container spacing={2} sx={{ mt: 0 }}>
              <Grid item xs={12} md={4}>
                <BroadcasterRequestsDoughnut />
              </Grid>
              <Grid item xs={12} md={8}>
                <BroadcasterStatsTable />
              </Grid>
            </Grid>
          </Box>
          <BundleList />
        </>
      )}
    </Box>
  );
});

export default Analytics;
