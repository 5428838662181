import { Box, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { networks } from 'src/config';

export const NetworkSelector = (): JSX.Element => {
  const [searchParams, setSearchParams] = useSearchParams();
  const chainId = searchParams.get('network');

  const onChangeHandler = (
    event: React.MouseEvent<HTMLElement>,
    network: string | null
  ): void => {
    network
      ? searchParams.set('network', network)
      : searchParams.delete('network');
    setSearchParams(searchParams);
  };

  return (
    <>
      <ToggleButtonGroup
        value={Number(chainId)}
        exclusive
        onChange={onChangeHandler}>
        {networks.map((network) => {
          return (
            <ToggleButton
              key={network.chainId}
              value={network.chainId}
              sx={{ maxHeight: '42px' }}
              size={'small'}>
              <Box
                sx={{
                  minWidth: '60px',
                  display: 'flex',
                  justifyContent: 'inherit',
                  alignItems: 'inherit',
                  fontSize: '16px',
                  fontWeight: 'bold',
                  px: 1,
                }}>
                {!!network.image && (
                  <Box
                    component="img"
                    sx={{
                      maxHeight: '24px',
                      pr: 1,
                    }}
                    alt={network.name}
                    src={network.image}
                  />
                )}
                {network.name}
              </Box>
            </ToggleButton>
          );
        })}
      </ToggleButtonGroup>
    </>
  );
};
