import {
  UseMutateFunction,
  useMutation,
  useQuery,
} from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { toast, ToastOptions } from 'react-toastify';
import {
  deleteConfigAsync,
  getConfigAsync,
  saveConfigAsync,
} from 'src/api/config.api';
import { ChainIdEnum } from 'src/shared/enums/chain-id.enum';
import { BroadcastConfig } from 'src/types';

const toastOprions: ToastOptions = {
  position: 'top-center',
  autoClose: 3000,
  hideProgressBar: false,
  closeOnClick: false,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: 'light',
};

const onError = (error: AxiosError): void => {
  const errorData = error?.response?.data as { message?: string } | undefined;

  if (errorData?.message) {
    toast.error(errorData.message, toastOprions);
  } else {
    toast.error('Failed to update config', toastOprions);
  }
};

type useConfigStats = {
  isLoading: boolean;
  data: any;
  create: UseMutateFunction<
    unknown,
    AxiosError<unknown, unknown>,
    {
      chainId: ChainIdEnum;
      config: BroadcastConfig;
    },
    unknown
  >;
  delete: UseMutateFunction<
    any,
    AxiosError<unknown, unknown>,
    {
      chainId: ChainIdEnum;
    },
    unknown
  >;
};

export const useConfig = (): useConfigStats => {
  const [searchParams] = useSearchParams();
  const chainId =
    (searchParams.get('network') as unknown as ChainIdEnum) || undefined;

  const { isLoading, data, refetch } = useQuery(['config', chainId], () =>
    getConfigAsync(chainId)
  );

  useEffect(() => {
    refetch();
  }, [chainId, refetch]);

  const createConfigQuery = useMutation(saveConfigAsync, {
    onSuccess: () => {
      toast.success('Config saved successfully!', toastOprions);
      refetch();
    },
    onError,
  });

  const deleteConfigQuery = useMutation(deleteConfigAsync, {
    onSuccess: () => {
      toast.success(
        'Config successfully reset to default options!',
        toastOprions
      );
      refetch();
    },
    onError,
  });

  return {
    isLoading:
      isLoading || createConfigQuery.isLoading || deleteConfigQuery.isLoading,
    data,
    create: createConfigQuery.mutate,
    delete: deleteConfigQuery.mutate,
  };
};
