import HelpOutlineRoundedIcon from '@mui/icons-material/HelpOutlineRounded';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {
  Chip,
  Collapse,
  IconButton,
  Popover,
  TableCell,
  TableRow,
} from '@mui/material';
import { useState } from 'react';
import { networks } from 'src/config';
import { BundleList } from 'src/types';

import { AddressLink } from '../AddressLink/AddressLink';
import { BundleCard } from '../BundleCard/BundleCard';
import { StatusReason } from '../StatusReason/StatusReason';
import { TimeAgo } from '../TimeAgo/TimeAgo';
import { TxLink } from '../TxLink/TxLink';

export const BundleRow = ({ row }: { row: BundleList }): JSX.Element => {
  const [open, setOpen] = useState(false);
  const network = networks.find((n) => n.chainId === row.chainId);

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
  };
  const handlePopoverClose = (): void => {
    setAnchorEl(null);
  };
  const infoOpen = Boolean(anchorEl);

  return (
    <>
      <TableRow sx={[{ td: { border: 'none' } }]}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
            sx={{ padding: 0, margin: 0 }}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>
          <TxLink hash={row.txHash} chainId={row.chainId} />
        </TableCell>
        <TableCell>
          {row.broadcasterId ? (
            <AddressLink address={row.broadcasterId} chainId={row.chainId} />
          ) : (
            <AddressLink
              address="Unknown Broadcaster"
              disableShortView
              disableCopyButton
            />
          )}
        </TableCell>
        <TableCell>
          {row.searcherAddress && (
            <AddressLink address={row.searcherAddress} chainId={row.chainId} />
          )}
        </TableCell>
        <TableCell align="right">
          {!['0', 'NaN'].includes(row.winnerSearcherBidUSD)
            ? '$' + Number(row.winnerSearcherBidUSD).toFixed(3)
            : ''}
        </TableCell>
        <TableCell align="right">
          {!['0', 'NaN'].includes(row.broadcasterProfitUSD)
            ? '$' + Number(row.broadcasterProfitUSD).toFixed(3)
            : ''}
        </TableCell>
        <TableCell align="right">{row.searcherOptionsCount}</TableCell>
        <TableCell align="right">
          {network ? network.name : row.chainId}
        </TableCell>
        <TableCell align="center">
          <>
            {!['0', 'NaN'].includes(row.broadcasterProfitUSD) ? (
              <Chip
                className="bundle-status-tag"
                sx={{ backgroundColor: '#4baf50' }}
                size="small"
                color="info"
                label="Success"
              />
            ) : row.submitAt ? (
              <Chip
                className="bundle-status-tag"
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
                sx={{ backgroundColor: '#dad72a', cursor: 'pointer' }}
                size="small"
                color="info"
                label="Empty"
                onDelete={() => {
                  // empty function used to show icon
                }}
                deleteIcon={<HelpOutlineRoundedIcon />}
              />
            ) : (
              <Chip
                className="bundle-status-tag"
                sx={{ backgroundColor: '#b5b5b5' }}
                size="small"
                color="info"
                label="Skipped"
              />
            )}
            <Popover
              sx={{
                pointerEvents: 'none',
              }}
              open={infoOpen}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              onClose={handlePopoverClose}
              disableRestoreFocus>
              <StatusReason
                statusReason={row.statusReason}
                className={'popover'}
              />
            </Popover>
          </>
        </TableCell>
        <TableCell align="right">
          <TimeAgo date={row.createdAt} />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0, background: '#f7f7f7' }}
          colSpan={10}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            {open && <BundleCard id={row.id} network={network} />}
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};
