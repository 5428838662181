import { Backdrop, CircularProgress } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { observer } from 'mobx-react';
import { useCookies } from 'react-cookie';
import { Navigate, useSearchParams } from 'react-router-dom';
import { loginUserAsync, logoutUserAsync } from 'src/api/auth.api';
import { useStores } from 'src/store/RootStore';

export const AuthJwt = observer((): JSX.Element => {
  const {
    AppStore: { setUser },
  } = useStores();

  const [searchParams] = useSearchParams();
  const [cookies] = useCookies(['logged_in']);

  const jwt = searchParams.get('jwt');
  const { isLoading, data } = useQuery(['login'], async () => {
    if (cookies['logged_in']) {
      try {
        setUser(undefined);
        await logoutUserAsync();
      } catch (e) {
        // empty
      }
    }

    const user = await loginUserAsync(jwt || '');
    if (user) {
      setUser(user);
    }

    return user;
  });

  if (isLoading) {
    return (
      <Backdrop open={true}>
        <CircularProgress />
      </Backdrop>
    );
  }

  if (data) {
    return <Navigate replace to={'/account/' + data.quicknodeId} />;
  }

  return <Navigate replace to={'/unauthorized'} />;
});

export default AuthJwt;
