export enum BlockbuildersEnum {
  flashbots = 'Flashbots',
  bloxroute = 'bloXroute',
  eden = 'Eden',
  openmev = 'OpenMEV',
  builder0x69 = 'Builder0x69',
  beaverbuild = 'Beaverbuild',
  blocknative = 'BlockNative',
  rsync = 'Rsync',
  eth = 'Eth',
  titan = 'Titan',
  f1b = 'f1b',
  gambit = 'Gambit',
  backrunme = 'Backrunme',
}
