import { Backdrop, CircularProgress } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { observer } from 'mobx-react';
import React from 'react';
import { pingAsync } from 'src/api/system.api';
import Unavailable from 'src/pages/Unavailable/Unavailable';

type SystemMiddlewareProps = {
  children: React.ReactElement;
};

const SystemMiddleware: React.FC<SystemMiddlewareProps> = observer(
  ({ children }) => {
    const ping = useQuery(['ping'], pingAsync);
    if (ping.isLoading) {
      return (
        <Backdrop open={true}>
          <CircularProgress />
        </Backdrop>
      );
    }

    if (!ping.data) {
      return <Unavailable />;
    }

    return children;
  }
);

export default SystemMiddleware;
