import Typography from '@mui/material/Typography';

function Title({ children }: { children: React.ReactNode }): JSX.Element {
  return (
    <Typography
      variant="h1"
      sx={{
        textAlign: 'center',
        borderBottom: '2px solid #df00ff',
        pb: '10px',
      }}
      gutterBottom>
      {children}
    </Typography>
  );
}

export default Title;
