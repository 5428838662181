import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { BundleList, Pagination } from 'src/types';

export const useBundles = (
  page: number,
  rowsPerPage: number
): {
  data: Pagination<BundleList[]>;
  chainId: string | null;
} => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [searchParams] = useSearchParams();
  const { quicknodeId } = useParams();
  const chainId = searchParams.get('network');
  const search = searchParams.get('search');

  const { data, refetch } = useQuery(
    ['bundles'],
    async () => {
      const { data } = await axios({
        url: `${apiUrl}/analytics/bundle`,
        params: {
          take: rowsPerPage,
          skip: page * rowsPerPage,
          chainId: Number(chainId),
          search: search || undefined,
          quicknodeId,
        },
      });
      return data;
    },
    { enabled: false }
  );

  useEffect(() => {
    refetch();
  }, [page, rowsPerPage, chainId, search, refetch]);

  return { data, chainId };
};
