import './AddressLink.scss';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Box, Link, Tooltip } from '@mui/material';
import { networks } from 'src/config';
import { ChainIdEnum } from 'src/shared/enums/chain-id.enum';

import { CopyToClipboard } from '../CopyToClipboard/CopyToClipboard';

export const AddressLink = ({
  address,
  chainId,
  length,
  disableCopyButton,
  disableShortView,
}: {
  address: string;
  chainId?: ChainIdEnum;
  length?: number;
  disableCopyButton?: boolean;
  disableShortView?: boolean;
}): JSX.Element => {
  const network = networks.find((n) => n.chainId === chainId);
  length = length ? Number(length) : 4;
  const formatedAddress = disableShortView
    ? address
    : address.slice(0, length + 2) + '...' + address.slice(length * -1);

  return (
    <Tooltip title={address}>
      <Box
        className="address-link"
        component={'span'}
        sx={{ whiteSpace: 'nowrap' }}>
        {network?.explorer ? (
          <Link href={`${network.explorer}/address/${address}`} target="_blank">
            {formatedAddress}
          </Link>
        ) : formatedAddress === 'Unknown Broadcaster' ? (
          <div style={{ color: '#a1a1a1' }}>{formatedAddress}</div>
        ) : (
          <>{formatedAddress}</>
        )}
        {!disableCopyButton && (
          <CopyToClipboard text={address}>
            <ContentCopyIcon className="copy-icon" />
          </CopyToClipboard>
        )}
      </Box>
    </Tooltip>
  );
};
