import { TextField } from '@mui/material';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';

export const Search = (props: {
  [key: string]: object | string | number;
}): JSX.Element => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [search, setSearch] = useState<string | null>(
    searchParams.get('search') ? searchParams.get('search') : ''
  );

  const handleChange = (value: string | null): void => {
    value ? searchParams.set('search', value) : searchParams.delete('search');
    setSearchParams(searchParams);
    setSearch(value);
  };

  const handleSearchChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const value = event.target.value;
    handleChange(value);
  };

  return (
    <TextField
      {...props}
      className="search"
      size="small"
      label="Case ID, Tx Hash"
      type="search"
      autoComplete="off"
      onChange={handleSearchChange}
      value={search}
    />
  );
};
