import { Box, Typography } from '@mui/material';

const Unavailable = (): JSX.Element => {
  return (
    <Box
      sx={{
        marginTop: '60px',
        textAlign: 'center',
      }}>
      <Typography variant="h1" style={{ color: '#fff', fontSize: 40 }}>
        Sorry, we’re down for maintenance right meow 😺
      </Typography>
    </Box>
  );
};

export default Unavailable;
