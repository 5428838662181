import { getApiClient } from './base.api';

const api = getApiClient();

export const pingAsync = async (): Promise<boolean> => {
  try {
    const response = await api.get('/system/ready');
    if (response?.data?.statusCode === 200) {
      return true;
    }
  } catch (e) {
    // empty
  }

  return false;
};
