import { QueryStatus, useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useEffect } from 'react';
import { BundleDetails } from 'src/types';

export const useBundle = (
  id: string
): {
  data: BundleDetails;
  status: QueryStatus;
  isLoading: boolean;
  isFetching: boolean;
} => {
  const apiUrl = process.env.REACT_APP_API_URL;

  const { data, refetch, isLoading, isFetching, status } = useQuery(
    ['bundles', id],
    async () => {
      const { data } = await axios({ url: `${apiUrl}/analytics/bundle/${id}` });

      return data;
    },
    { enabled: false }
  );

  useEffect(() => {
    refetch();
  }, [refetch]);

  return { data, status, isLoading, isFetching };
};
