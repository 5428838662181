import './AccountConfigForm.scss';

import { zodResolver } from '@hookform/resolvers/zod';
import { InfoOutlined } from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Box,
  FormGroup,
  Grid,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useConfig } from 'src/hooks';
import { ChainIdEnum } from 'src/shared/enums/chain-id.enum';
import { BroadcastConfig } from 'src/types';
import { boolean, literal, number, object, preprocess, string } from 'zod';

import AreYouSure from '../AreYouSure/AreYouSure';
import { InputNumberController } from './components/InputNumberController/InputNumberController';
import { InputTextController } from './components/InputTextController/InputTextController';
import { SwitchController } from './components/SwitchController/SwitchController';
import { TrustedBroadcastersController } from './components/TrustedBroadcastersController/TrustedBroadcastersController';

const parseNumberPreprocess = (val: unknown): number | null => {
  try {
    return typeof val !== 'number'
      ? parseInt(
          string()
            .trim()
            .parse(val || 0),
          10
        )
      : val;
  } catch (e) {
    // empty
  }

  return null;
};

const accountConfigSchema = object({
  broadcaster_address: string()
    .trim()
    .regex(/^0x[a-fA-F0-9]{40}$/, 'Enter a valid address')
    .optional()
    .or(literal(''))
    .nullable(),
  submit_config: object({
    allow_reverts: boolean().optional(),
    public_fallback: boolean().optional(),
    private_resubmit_count: preprocess(
      parseNumberPreprocess,
      number({
        invalid_type_error: 'min: 1',
      })
        .min(1, 'min: 1')
        .max(10, 'max: 10')
    ),
    trusted_builders: string().array().optional(),
  }),
  ofa_config: object({
    enabled: boolean(),
    allow_front_run: boolean(),
    wait_searcher_ms: preprocess(
      parseNumberPreprocess,
      number().min(200, 'min: 200').max(180000, 'max: 180000').nullable()
    ),
  }),
});

const AccountConfigForm = (props: { chainId: ChainIdEnum }): JSX.Element => {
  const { chainId } = props;

  const config = useConfig();

  const { control, handleSubmit, formState, reset } = useForm<BroadcastConfig>({
    resolver: zodResolver(accountConfigSchema),
    defaultValues: config.data,
  });

  useEffect(() => {
    reset(config.data as BroadcastConfig);
  }, [config.data, reset]);

  const onSubmitHandler: SubmitHandler<BroadcastConfig> = (
    values: BroadcastConfig
  ) => {
    if (
      values.broadcaster_address === '' ||
      values.broadcaster_address?.trim() === ''
    ) {
      values.broadcaster_address = undefined;
    }

    config.create({
      chainId,
      config: values,
    });
  };

  const onResetHandler = (): void => {
    config.delete({
      chainId,
    });
    reset(config.data);
  };

  return (
    <Box
      className="account-config-form"
      component="form"
      onSubmit={handleSubmit(onSubmitHandler)}>
      <Box sx={{ py: 4 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Box className={'table-row'}>
              <Typography variant={'h2'}>Submit options</Typography>
            </Box>

            <Box className={'table-row'}>
              <Tooltip title="A boolean flag indicating if the broadcaster transaction could be reverted">
                <Box display={'flex'} alignItems={'center'}>
                  {'Allow reverts'}
                  <InfoOutlined className={'info-icon'} />
                </Box>
              </Tooltip>
              <SwitchController
                disabled={config.isLoading}
                name={'submit_config.allow_reverts'}
                control={control}
              />
            </Box>

            <Box className={'table-row'}>
              <Tooltip title="A boolean flag indicating if there should be an additional public submission for the initial broadcaster transaction after private_resubmit_count tries">
                <Box display={'flex'} alignItems={'center'}>
                  {'Public fallback'}
                  <InfoOutlined className={'info-icon'} />
                </Box>
              </Tooltip>
              <SwitchController
                disabled={config.isLoading}
                name={'submit_config.public_fallback'}
                control={control}
              />
            </Box>

            <Box className={'table-row'}>
              <Tooltip title="An integer value that represents the number of blocks to mine transactions privately">
                <Box display={'flex'} alignItems={'center'}>
                  {'Private resubmit count'}
                  <InfoOutlined className={'info-icon'} />
                </Box>
              </Tooltip>
              <Box sx={{ display: 'inline-block', maxWidth: '100px' }}>
                <InputNumberController
                  name={'submit_config.private_resubmit_count'}
                  control={control}
                  error={Boolean(
                    formState.errors.submit_config?.private_resubmit_count
                  )}
                  helperText={
                    formState.errors.submit_config?.private_resubmit_count
                      ?.message
                  }
                  disabled={config.isLoading}
                />
              </Box>
            </Box>

            {chainId === ChainIdEnum.eth && (
              <Box className={'table-row block'}>
                <Box display={'block'} sx={{ mb: 2 }}>
                  <Tooltip title="A list of dictionary of trusted MEV builders that will be used for transaction private mining">
                    <Box display={'flex'} alignItems={'center'}>
                      <Typography variant={'h3'}>Trusted builders</Typography>
                      <InfoOutlined className={'info-icon'} />
                    </Box>
                  </Tooltip>
                </Box>
                <FormGroup>
                  <Grid container>
                    <TrustedBroadcastersController
                      disabled={config.isLoading}
                      name={'submit_config.trusted_builders'}
                      control={control}
                    />
                  </Grid>
                </FormGroup>
              </Box>
            )}
          </Grid>
          <Grid item xs={12} md={6}>
            <Box className={'table-row'}>
              <Typography variant={'h2'}>Order flow auction</Typography>
            </Box>

            <Box className={'table-row'}>
              <Tooltip title="A boolean flag indicating if an auction should be performed">
                <Box display={'flex'} alignItems={'center'}>
                  {'Hold the auctions'}
                  <InfoOutlined className={'info-icon'} />
                </Box>
              </Tooltip>
              <SwitchController
                disabled={config.isLoading}
                name={'ofa_config.enabled'}
                control={control}
              />
            </Box>

            <Box className={'table-row'}>
              <Tooltip title="A boolean flag indicating if searchers allow creating bundles that contain frontrun transactions">
                <Box display={'flex'} alignItems={'center'}>
                  {'Allow front run'}
                  <InfoOutlined className={'info-icon'} />
                </Box>
              </Tooltip>
              <SwitchController
                disabled={config.isLoading}
                name={'ofa_config.allow_front_run'}
                control={control}
              />
            </Box>
            {chainId !== ChainIdEnum.eth && (
              <Box className={'table-row'}>
                <Tooltip title="The time (ms) allotted for the auction">
                  <Box display={'flex'} alignItems={'center'}>
                    {'Auction time, ms'}
                    <InfoOutlined className={'info-icon'} />
                  </Box>
                </Tooltip>
                <Box sx={{ display: 'inline-block', maxWidth: '100px' }}>
                  <InputNumberController
                    name={'ofa_config.wait_searcher_ms'}
                    control={control}
                    error={Boolean(
                      formState.errors.ofa_config?.wait_searcher_ms
                    )}
                    helperText={
                      formState.errors.ofa_config?.wait_searcher_ms?.message
                    }
                    disabled={config.isLoading}
                  />
                </Box>
              </Box>
            )}

            <Box className={'table-row block'} sx={{ pb: '36px !important' }}>
              <Box display={'block'} sx={{ mb: 2 }}>
                <Tooltip title="The address which will receive a profit from MEV">
                  <Box display={'flex'} alignItems={'center'}>
                    <Typography variant={'h3'}>Payout address</Typography>
                    <InfoOutlined className={'info-icon'} />
                  </Box>
                </Tooltip>
              </Box>
              <InputTextController
                name="broadcaster_address"
                control={control}
                error={Boolean(formState.errors.broadcaster_address)}
                placeholder={'0x0000000000000000000000000000000000000000'}
                helperText={
                  formState.errors.broadcaster_address?.message ||
                  `By default, the profit will be sent to the signer's address (address 'from')`
                }
                disabled={config.isLoading}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={2}
        sx={{ width: '100%' }}>
        <LoadingButton
          loading={config.isLoading}
          variant="contained"
          type={'submit'}
          disabled={config.isLoading}>
          Save
        </LoadingButton>
        <AreYouSure
          onClick={onResetHandler}
          text="Are you sure you want to return the default options?">
          <LoadingButton
            loading={config.isLoading}
            variant="outlined"
            disabled={config.isLoading}>
            Use default options
          </LoadingButton>
        </AreYouSure>
      </Stack>
    </Box>
  );
};

export default AccountConfigForm;
