// import { CssBaseline } from '@mui/material';
// eslint-disable-next-line no-restricted-imports
import 'react-toastify/dist/ReactToastify.css';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import { observer } from 'mobx-react';
import { Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import Layout from './components/Layout/Layout';
import AuthMiddleware from './middleware/AuthMiddleware';
import ProtectedRoute from './middleware/ProtectedRoute';
import SystemMiddleware from './middleware/SystemMiddleware';
import AccountConfig from './pages/AccountConfig/AccountConfig';
import Analytics from './pages/Analytics/Analytics';
import AuthJwt from './pages/AuthJwt/AuthJwt';
import NotFound from './pages/NotFound/NotFound';
import Unauthorized from './pages/Unauthorized/Unauthorized';

const mdTheme = createTheme({
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          background: '#fff',
          color: '#2f0743',
        },
      },
    },
  },
  palette: {
    primary: {
      main: '#FC9127',
      contrastText: '#fff',
    },
    background: {
      default: 'rgba(127,0,255,1)',
      paper: '#fff',
    },
  },
  typography: {
    fontFamily: ['"Helvetica Neue"'].join(','),
    h1: {
      fontSize: 28,
      fontWeight: 500,
      color: '#2f0743',
    },
    h2: {
      fontSize: 24,
      fontWeight: 500,
      color: '#2f0743',
    },
    h3: {
      fontSize: 18,
      fontWeight: 500,
      color: '#2f0743',
    },
  },
});

const App = observer(() => {
  return (
    <ThemeProvider theme={mdTheme}>
      {/* <CssBaseline /> */}
      <Layout>
        <SystemMiddleware>
          <AuthMiddleware>
            <Routes>
              <Route path="/login" element={<AuthJwt />} />
              <Route path="/unauthorized" element={<Unauthorized />} />
              <Route path="/account/:quicknodeId" element={<Analytics />} />
              <Route
                path="/account/:quicknodeId/config"
                element={
                  <ProtectedRoute>
                    <AccountConfig />
                  </ProtectedRoute>
                }
              />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </AuthMiddleware>
        </SystemMiddleware>
      </Layout>
      <ToastContainer />
    </ThemeProvider>
  );
});

export default App;
