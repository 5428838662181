import { Network } from '../types';

export const networks: Network[] = [
  {
    chainId: 1,
    name: 'ETH',
    explorer: 'https://etherscan.io',
    nativeCurrencySymbol: 'ETH',
    image: '/eth-icon.svg',
    color: '#517be7',
  },
  {
    chainId: 56,
    name: 'BSC',
    explorer: 'https://bscscan.com/',
    nativeCurrencySymbol: 'BNB',
    image: '/bsc-icon.svg',
    color: '#ecb42e',
  },
];
