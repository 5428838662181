import { observer } from 'mobx-react';
import React from 'react';
import { useCookies } from 'react-cookie';
import Unauthorized from 'src/pages/Unauthorized/Unauthorized';

import { useStores } from '../store/RootStore';

type ProtectedRouteProps = {
  children: React.ReactElement;
};

const ProtectedRoute: React.FC<ProtectedRouteProps> = observer(
  ({ children }) => {
    const [cookies] = useCookies(['logged_in']);
    const {
      AppStore: { getUser },
    } = useStores();

    if (!cookies['logged_in'] || !getUser()) {
      return <Unauthorized />;
    }

    return children;
  }
);

export default ProtectedRoute;
