import { Box, Typography } from '@mui/material';
import { useCookies } from 'react-cookie';

const Unauthorized = (): JSX.Element => {
  // eslint-disable-next-line unused-imports/no-unused-vars
  const [cookie, setCookie] = useCookies(['logged_in']);
  setCookie('logged_in', false, { maxAge: 1 });

  return (
    <Box
      sx={{
        marginTop: '60px',
        textAlign: 'center',
      }}>
      <Typography variant="h1" style={{ color: '#fff', fontSize: 80 }}>
        401
      </Typography>
      <div>
        <Typography variant="h2" style={{ color: '#fff', fontSize: 32 }}>
          Unauthorized
        </Typography>
      </div>
    </Box>
  );
};

export default Unauthorized;
