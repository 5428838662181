import './BundleList.scss';

import {
  Box,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useBundles } from 'src/hooks';
import { BundleList as BundleListResponse } from 'src/types';

import { Search } from '../Search/Search';
import { BundleRow } from './BundleRow';

export const BundleList = (): JSX.Element => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const { data } = useBundles(page, rowsPerPage);

  useEffect(() => {
    setPage(0);
  }, []);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number
  ): void => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Box sx={{ py: 4 }}>
      <Stack
        direction={{ md: 'row', xs: 'column' }}
        alignItems="center"
        spacing={2}>
        <Typography variant="h2" gutterBottom sx={{ flex: '1 0 auto' }}>
          Successful auctions
        </Typography>
        <Search
          sx={{
            width: '100%',
            maxWidth: '420px',
          }}
        />
      </Stack>
      <TableContainer
        className="bundles-table-container"
        sx={{
          marginTop: '20px',
        }}>
        <Table size="small" aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell align="left" width={'30px'} />
              <TableCell align="left" width={'150px'}>
                Target Tx Hash
              </TableCell>
              <TableCell align="left" width={'150px'}>
                Broadcaster ID
              </TableCell>
              <TableCell align="left" width={'150px'}>
                Winner ID
              </TableCell>
              <TableCell align="right">Expected Profit</TableCell>
              <TableCell align="right">Actual Profit</TableCell>
              <TableCell align="right">Bids</TableCell>
              <TableCell align="right">Network</TableCell>
              <TableCell align="center">Status</TableCell>
              <TableCell align="right" width={'145px'}>
                Age
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!!data?.items.length &&
              data.items.map((row: BundleListResponse) => (
                <BundleRow key={row.id} row={row} />
              ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell
                sx={{ border: 'none', marginBottom: 0, paddingBottom: 0 }}
                colSpan={10}>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={data?.total || 0}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </Box>
  );
};
