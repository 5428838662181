import { Box, CircularProgress, Paper } from '@mui/material';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import AccountConfigForm from 'src/components/AccountConfigForm/AccountConfigForm';
import Title from 'src/components/Title/Title';
import { networks } from 'src/config';
import { ChainIdEnum } from 'src/shared/enums/chain-id.enum';

const AccountConfig = (): JSX.Element => {
  const [searchParams, setSearchParams] = useSearchParams();
  const chainId = searchParams.get('network');
  useEffect(() => {
    if (!chainId) {
      searchParams.set('network', String(ChainIdEnum.eth));
      setSearchParams(searchParams);
    }
  }, [chainId, searchParams, setSearchParams]);

  const currentNetwork = networks.find((n) => n.chainId === Number(chainId));
  if (!chainId || !currentNetwork) {
    return (
      <Box
        component={Paper}
        sx={{
          px: 2,
          py: 20,
          display: 'flex',
          justifyContent: 'center',
          minHeight: '300px',
        }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box
      component={Paper}
      sx={{
        px: 2,
        py: 4,
      }}>
      <Title>Account config: {currentNetwork.name}</Title>
      <AccountConfigForm
        key={'account-config-' + chainId}
        chainId={Number(chainId)}
      />
    </Box>
  );
};

export default AccountConfig;
