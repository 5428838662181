import { ChainIdEnum } from 'src/shared/enums/chain-id.enum';
import { BroadcastConfig } from 'src/types';

import { getApiClient } from './base.api';

const api = getApiClient();

export const getDefaultConfigAsync = async (
  chainId?: ChainIdEnum
): Promise<any> => {
  const response = await api.get('/config/default', {
    params: {
      chainId,
    },
  });

  if (response.data) {
    return response.data;
  }

  return {};
};

export const getConfigAsync = async (chainId?: ChainIdEnum): Promise<any> => {
  const response = await api.get('/config', {
    params: {
      chainId,
    },
  });

  if (response.data) {
    return response.data;
  }

  return {};
};

export const saveConfigAsync = async (params: {
  chainId: ChainIdEnum;
  config: BroadcastConfig;
}): Promise<any> => {
  const { chainId, config } = params;
  const response = await api.post('/config', {
    chainId,
    config,
  });

  if (response.data) {
    return response.data;
  }

  return {};
};

export const deleteConfigAsync = async (params: {
  chainId: ChainIdEnum;
}): Promise<any> => {
  const { chainId } = params;
  const response = await api.delete('/config', {
    data: {
      chainId,
    },
  });

  if (response.data) {
    return response.data;
  }

  return {};
};
