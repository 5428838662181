import { Switch } from '@mui/material';
import { Control, Controller, FieldValues } from 'react-hook-form';

type SwitchControllerProps = {
  name: string;
  control: Control<FieldValues>;
  disabled?: boolean;
};

export const SwitchController = (props: SwitchControllerProps): JSX.Element => {
  const { control, name, disabled } = props;

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={false}
      render={({ field }) => (
        <Switch
          disabled={!!disabled}
          onChange={(e) => field.onChange(e.target.checked)}
          checked={field.value}
        />
      )}
    />
  );
};
