import { TextField } from '@mui/material';
import { Control, Controller, FieldValues } from 'react-hook-form';

type InputTextControllerProps = {
  name: string;
  control: Control<FieldValues>;
  error: boolean;
  helperText?: string;
  placeholder?: string;
  disabled?: boolean;
};

export const InputTextController = (
  props: InputTextControllerProps
): JSX.Element => {
  const { control, name, error, helperText, placeholder, disabled } = props;
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { ref, ...field } }) => {
        if (!field.value) {
          field.value = '';
        }

        return (
          <TextField
            autoComplete="off"
            sx={{ width: '100%' }}
            margin="normal"
            size="small"
            error={error}
            helperText={helperText}
            inputRef={ref}
            placeholder={placeholder}
            disabled={!!disabled}
            {...field}
          />
        );
      }}
    />
  );
};
