import { action, makeObservable, observable } from 'mobx';

import { QuicknodeAccountInterface } from '../shared/interfaces/quicknode-account.interface';

class AppStore {
  apiUrl = process.env.REACT_APP_API_URL;
  user: QuicknodeAccountInterface | undefined = undefined;

  constructor() {
    makeObservable(this, {
      apiUrl: observable,
      getApiUrl: action,
      user: observable,
      getUser: action,
      setUser: action,
    });
  }

  getApiUrl = (): string | undefined => this.apiUrl;

  getUser = (): QuicknodeAccountInterface | undefined => this.user;
  setUser = (user: QuicknodeAccountInterface | undefined): void => {
    this.user = user;
  };
}

export default new AppStore();
