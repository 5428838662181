import { ChainIdEnum } from 'src/shared/enums/chain-id.enum';

import { getApiClient } from './base.api';

const api = getApiClient();

export const getAnalysticsAsync = async (
  quicknodeId: string,
  chainId?: ChainIdEnum,
  period?: string
): Promise<any> => {
  const response = await api.get('/analytics', {
    params: {
      chainId,
      quicknodeId,
      period,
    },
  });

  if (response.data) {
    return response.data;
  }

  return {};
};
