import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { getAnalysticsAsync } from 'src/api/analystics.api';
import { ChainIdEnum } from 'src/shared/enums/chain-id.enum';
import { RequestsStats } from 'src/types';

export const useAnalytics = (
  period?: 'week' | 'month' | 'day'
): {
  data: RequestsStats;
  isLoading: boolean;
} => {
  const [searchParams] = useSearchParams();

  if (!period) {
    period = (searchParams.get('period') as 'week' | 'month' | 'day') || 'day';
  }

  const { quicknodeId } = useParams();
  const chainId =
    (searchParams.get('network') as unknown as ChainIdEnum) || undefined;

  const { data, isLoading, refetch } = useQuery(
    ['requests'],
    () => getAnalysticsAsync(quicknodeId as string, chainId, period),
    { enabled: false }
  );

  useEffect(() => {
    refetch();
    const timer = setInterval(refetch, 15000);
    return () => {
      clearInterval(timer);
    };
  }, [quicknodeId, chainId, refetch]);

  return { data, isLoading };
};
