import { QuicknodeAccountInterface } from '../shared/interfaces/quicknode-account.interface';
import { getApiClient } from './base.api';

const api = getApiClient();

export const loginUserAsync = async (
  jwt: string
): Promise<QuicknodeAccountInterface> => {
  const response = await api.post('/auth/login', null, {
    headers: {
      authorization: 'Bearer ' + jwt,
    },
  });
  return response.data;
};

export const getProfileAsync = async (): Promise<QuicknodeAccountInterface> => {
  const response = await api.get('/auth/profile');
  return response.data;
};

export const logoutUserAsync = async (): Promise<QuicknodeAccountInterface> => {
  const response = await api.get('/auth/logout');
  return response.data;
};
