import { TextField } from '@mui/material';
import { Control, Controller, FieldValues } from 'react-hook-form';

type InputNumberControllerProps = {
  name: string;
  control: Control<FieldValues>;
  error: boolean;
  helperText?: string;
  disabled?: boolean;
};

export const InputNumberController = (
  props: InputNumberControllerProps
): JSX.Element => {
  const { control, name, error, helperText, disabled } = props;

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { ref, ...field } }) => (
        <TextField
          sx={{ width: '100%' }}
          margin="normal"
          size="small"
          type={'number'}
          error={error}
          helperText={helperText}
          inputRef={ref}
          disabled={!!disabled}
          {...field}
        />
      )}
    />
  );
};
